@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: 'Spotify';
  src: url('./fonts/spotifyFont.woff2') format('woff2');
  /* You can also include other font properties like font-weight and font-style if needed. */
}


@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
  }
}


body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Spotify' , sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000000;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.hover\:blocku:hover .image_artist {
  opacity:1;
}
.hover\:bottomd:hover .image_spotify {
  bottom: 1rem;
  opacity:1;
}

.swiper-button-next:after{
  opacity: 0;
  display: flex;
  position: absolute;
  color: white;
  font-size: 25px !important;
  right: 10px;
  transition: 0.3s;
}
.swiper-button-prev:after{
  opacity:0 ;
  display: flex;
  position: absolute;
  color: white;
  font-size: 25px !important;
  right: 10px;
  transition: 0.3s;
}
.swiper{
  position: static !important;
}
.swiper:hover .swiper-button-prev::after,.swiper:hover .swiper-button-next::after {
  opacity: 1;

}
.swiper-button-next, .swiper-rtl .swiper-button-prev {
  right: var(--swiper-navigation-sides-offset, -35px) !important;
  left: auto;
}
.swiper-button-prev, .swiper-rtl .swiper-button-next {
  left: var(--swiper-navigation-sides-offset, -30px) !important;
  right: auto;
}

.custom-slider {
  margin: 0 auto; /* Center the slider horizontally */ /* Change the background color */
}
.custom-slider-mobile {
  margin: 0 auto; /* Center the slider horizontally */ /* Change the background color */
}
.handleVolume{
  width: 100px !important;
}

.custom-slider .rc-slider-track {
  background-color: #BABABA;
  margin: 5px 0;

   /* Change the track color */
}
.custom-slider .rc-slider-rail{
  background-color: #282828;
  margin: 5px 0;
}
.custom-slider .rc-slider-step{
  margin: 5px 0;
}
.custom-slider-mobile .rc-slider-track {
  background-color: #BABABA;
   /* Change the track color */
}
.rc-slider{
  padding: 0 !important;
   /* Change the track color */
}
.custom-slider-mobile .rc-slider-rail{
  background-color: #282828;
}

.custom-slider .rc-slider-handle {
 visibility: hidden; /* Change the handle border color */
 margin: 5px 0;
}
.custom-slider-mobile .rc-slider-handle {
 visibility: hidden; /* Change the handle border color */
}
.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
  box-shadow: none !important;
  border: none; /* Change the handle border color */
}

.custom-slider:hover .rc-slider-handle {
  visibility: visible;
  background-color: white;
  border: none;
  opacity: 1;
  width: 17px;
  height: 17px;
  margin-top: -2px;
 
}
.custom-slider .rc-slider-handle:active {
  box-shadow: none;
  border: none; /* Change the height of the thumb */
  /* Add more custom styles as needed */
}
.custom-slider-mobile .rc-slider-handle:active {
  box-shadow: none;
  border: none; /* Change the height of the thumb */
  /* Add more custom styles as needed */
}
.custom-slider:hover .rc-slider-track {
  background-color: #CD2C21;

}
.active{
  color: #fff;
  background-color: #121212;
}

/* styles.css */
.loading-spinner {
  width: 50px;
  height: 50px;
}





@media (max-width: 768px) {
  .spotify-list:hover{
    background-color: rgba(132, 132, 132, 0.2);
    cursor: pointer;
  }
  .spotify-list:hover .number-s-list{
    display: none;
  }
  .navbar{
    width: 100%;
  }
  
}

@media (min-width: 768px) {
  .spotify-list:hover{
    background-color: rgba(132, 132, 132, 0.2);
    cursor: pointer;
  }
  .spotify-list:hover .number-s-list{
    display: none;
  }
  .spotify-list:hover .play-s-list{
    display: inline;
  }
}

.custom-slider-spotify .rc-slider-track {
  background-color: white;
  margin: 5px 0;

   /* Change the track color */
}
.custom-slider-spotify .rc-slider-rail{
  background-color: #282828;
  margin: 5px 0;
}
.custom-slider-spotify .rc-slider-step{
  margin: 5px 0;
}
.custom-slider-spotify-mobile .rc-slider-track {
  background-color: white;
   /* Change the track color */
}
.rc-slider{
  padding: 0 !important;
   /* Change the track color */
}
.custom-slider-spotify-mobile .rc-slider-rail{
  background-color: #282828;
}

.custom-slider-spotify .rc-slider-handle {
 visibility: hidden; /* Change the handle border color */
 margin: 5px 0;
}
.custom-slider-spotify-mobile .rc-slider-handle {
 visibility: hidden; /* Change the handle border color */
}
.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
  box-shadow: none !important;
  border: none; /* Change the handle border color */
}

.custom-slider-spotify:hover .rc-slider-handle {
  visibility: visible;
  background-color: white;
  border: none;
  opacity: 1;
  width: 14px;
  height: 14px;
  margin-top: -1px;
 
}
.custom-slider-spotify .rc-slider-handle:active {
  box-shadow: none;
  border: none; /* Change the height of the thumb */
  /* Add more custom styles as needed */
}
.custom-slider-spotify-mobile .rc-slider-handle:active {
  box-shadow: none;
  border: none; /* Change the height of the thumb */
  /* Add more custom styles as needed */
}
.custom-slider-spotify:hover .rc-slider-track {
  background-color: #22c55e;
}



.custom-slider-spotify2 .rc-slider-track {
  background-color: white;
  margin: 5px 0;

   /* Change the track color */
}
.custom-slider-spotify2 .rc-slider-rail{
  background-color: #282828;
  margin: 5px 0;
}
.custom-slider-spotify2 .rc-slider-step{
  margin: 5px 0;
}
.custom-slider-spotify2 .rc-slider-handle {
  background-color: white;
  border: none;
  opacity: 1;
  width: 18px;
  height: 18px;
  margin-top: -1px;
}
.custom-slider-spotify2 .rc-slider-handle:active {
  box-shadow: none;
  border: none; /* Change the height of the thumb */
  /* Add more custom styles as needed */
}
.custom-slider-spotify2:active .rc-slider-track {
  background-color: #22c55e;
}
